import React, { useState, useContext } from "react";
import ToastContext from "./ToastContext";
import { Toast, ToastContainer, Button } from "react-bootstrap";
import "./toast.css";
import userService from "../../Services/userService";
import UserContext from "../UserContext/UserContext";
import { Howler } from "howler";
import { useNavigate } from "react-router-dom";

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);
  const { userData, accessToken, imageSasToken, wellnessAccepted } =
    useContext(UserContext);
  const navigate = useNavigate();
  const addToast = (
    title,
    category = "default",
    variant = "primary",
    documentInfo = null,
    setIsCriticalSoundPlaying = null,
    oldDocumentInfo = null
  ) => {
    // Since content is hardcoded for "Critical", no need to pass it as a parameter
    setToasts((prevState) => [
      ...prevState,
      {
        title,
        category,
        variant,
        documentInfo,
        setIsCriticalSoundPlaying,
        oldDocumentInfo,
      },
    ]);
  };

  const respondingButton = (subject, index) => {
    const payload = {
      entityId: userData?.userInfo?.entityId,
      subjectId: subject.id,
      action: "Responded",
    };

    if (userData?.userInfo?.firstName) {
      payload.firstName = userData?.userInfo?.firstName;
    }

    if (userData?.userInfo?.lastName) {
      payload.lastName = userData?.userInfo?.lastName;
    }

    if (userData?.userInfo?.title) {
      payload.title = userData?.userInfo?.title;
    }

    userService
      .logUsersAlertAction(payload, accessToken)
      .then((response) => {
        removeToast(index);
      })
      .catch(() => {});
  };

  const busyButton = (subject, index) => {
    const payload = {
      entityId: userData?.userInfo?.entityId,
      subjectId: subject.id,
      action: "Busy",
    };
    userService
      .logUsersAlertAction(payload, accessToken)
      .then((response) => {
        removeToast(index);
      })
      .catch(() => {});
  };

  const removeToast = (index) => {
    setToasts((prevState) => prevState.filter((_, idx) => idx !== index));
  };

  const contextValue = {
    addToast,
    removeToast,
  };
  const showOverlay = toasts.some((toast) => toast.category === "Critical");
  return (
    <ToastContext.Provider value={contextValue}>
      {children}
      {showOverlay && <div className="blur-overlay"></div>}
      <ToastContainer
        position="top-end"
        style={{ marginTop: 85, marginRight: 12 }}
      >
        {toasts.map((toast, index) => {
          const base64SvgIndex = userData?.entityAssets?.alertStates?.findIndex(
            (state) =>
              state.priorityValue === toast?.documentInfo?.priorityValue
          );

          const base64Svg =
            base64SvgIndex !== -1
              ? !wellnessAccepted
                ? userData?.entityAssets?.alertStates[base64SvgIndex]
                    ?.wellnessSvg
                : userData?.userInfo?.darkTheme
                ? userData?.entityAssets?.alertStates[base64SvgIndex]
                    ?.darkThemeSvg
                : userData?.entityAssets?.alertStates[base64SvgIndex]
                    ?.lightThemeSvg
              : userData?.userInfo?.darkTheme
              ? userData?.entityAssets?.alertStates[
                  userData?.entityAssets?.alertStates?.length - 1
                ]?.darkThemeSvg
              : userData?.entityAssets?.alertStates[
                  userData?.entityAssets?.alertStates?.length - 1
                ]?.lightThemeSvg;

          const svgURI = `data:image/svg+xml;base64,${base64Svg}`;

          const priorityDescription =
            base64SvgIndex !== -1
              ? !wellnessAccepted
                ? userData?.entityAssets?.alertStates[base64SvgIndex]
                    ?.wellnessPriorityDescription
                : userData?.entityAssets?.alertStates[base64SvgIndex]
                    ?.priorityDescription
              : userData?.entityAssets?.alertStates[
                  userData?.entityAssets?.alertStates?.length - 1
                ]?.priorityDescription;

          const MAX_WIDTH = 20; // Define your maximum width here

          let truncatedLocation = "--"; // Default value if no tiers fit
          let lorawanTruncatedLocation = "--";

          if (toast?.documentInfo) {
            const { tier1Location, tier2Location, tier3Location } =
              toast?.documentInfo;

            if (tier1Location && tier2Location && tier3Location) {
              const fullLocation = `${tier1Location} - ${tier2Location} - ${tier3Location}`;
              if (fullLocation.length <= MAX_WIDTH) {
                truncatedLocation = fullLocation;
              } else if (
                `${tier2Location} - ${tier3Location}`.length <= MAX_WIDTH
              ) {
                truncatedLocation = `${tier2Location} - ${tier3Location}`;
              } else if (tier3Location.length <= MAX_WIDTH) {
                truncatedLocation = tier3Location;
              }
            } else if (tier1Location && tier2Location) {
              const twoTiersLocation = `${tier1Location} - ${tier2Location}`;
              if (twoTiersLocation.length <= MAX_WIDTH) {
                truncatedLocation = twoTiersLocation;
              } else if (tier2Location.length <= MAX_WIDTH) {
                truncatedLocation = tier2Location;
              }
            } else if (tier1Location && tier1Location.length <= MAX_WIDTH) {
              truncatedLocation = tier1Location;
            }
          }

          // ---------------------- LORAWAN location  ---------------------- //
          if (toast?.documentInfo?.loRaWANLastLocation) {
            const { tier1, tier2, tier3 } =
              toast?.documentInfo?.loRaWANLastLocation;

            if (tier1 && tier2 && tier3) {
              const fullLocation = `${tier1} - ${tier2} - ${tier3}`;
              if (fullLocation.length <= MAX_WIDTH) {
                lorawanTruncatedLocation = fullLocation;
              } else if (`${tier2} - ${tier3}`.length <= MAX_WIDTH) {
                lorawanTruncatedLocation = `${tier2} - ${tier3}`;
              } else if (tier3.length <= MAX_WIDTH) {
                lorawanTruncatedLocation = tier3;
              }
            } else if (tier1 && tier2) {
              const twoTiersLocation = `${tier1} - ${tier2}`;
              if (twoTiersLocation.length <= MAX_WIDTH) {
                lorawanTruncatedLocation = twoTiersLocation;
              } else if (tier2.length <= MAX_WIDTH) {
                lorawanTruncatedLocation = tier2;
              }
            } else if (tier1 && tier1?.length <= MAX_WIDTH) {
              lorawanTruncatedLocation = tier1;
            }
          }
          const truncatedLocationType =
            toast?.documentInfo?.connectionType === "lorawan"
              ? lorawanTruncatedLocation
              : truncatedLocation;

          if (toast.category === "Critical") {
            // Special rendering for "Critical" category
            return (
              <Toast
                key={index}
                // onClose={() => removeToast(index)}
                className={`toaster-bg-${toast.variant} critical-toast-card ${
                  userData?.userInfo?.darkTheme &&
                  "toast-top-section-dark-theme-color"
                }`} // Custom class for critical toast
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 9999,
                }}
                autohide={false} // Critical toast does not autohide
              >
                <div
                  className={`critical-toast-card-top-section text-center d-flex ${
                    userData?.userInfo?.darkTheme &&
                    "toast-top-section-dark-theme-color"
                  }`}
                  style={{ height: 245 }}
                >
                  {/* <div className="d-flex"> */}
                  <img
                    src={
                      toast?.documentInfo?.imageUrl
                        ? `${toast?.documentInfo?.imageUrl}${imageSasToken}`
                        : toast?.documentInfo?.userId
                        ? "/svgs/Light_Checkout_Sensor_PFP.svg"
                        : toast?.documentInfo?.gender === "M"
                        ? userData?.userInfo?.darkTheme
                          ? "/svgs/Light_Male_Square_PFP.svg"
                          : "/svgs/Dark_Male_Square_PFP.svg"
                        : userData?.userInfo?.darkTheme
                        ? "/svgs/Light_Female_Square_PFP.svg"
                        : "/svgs/Dark_Female_Square_PFP.svg"
                    }
                    className={
                      toast?.documentInfo?.userId
                        ? `critical-toast-card-profile-image ${
                            userData?.userInfo?.darkTheme &&
                            "toast-border-dark-theme"
                          }`
                        : toast?.documentInfo?.imageUrl
                        ? `critical-toast-card-profile-image ${
                            userData?.userInfo?.darkTheme &&
                            "toast-border-dark-theme"
                          }`
                        : `critical-toast-card-profile-image ${
                            userData?.userInfo?.darkTheme &&
                            "toast-border-dark-theme"
                          }`
                    }
                    onError={(e) => {
                      if (toast?.documentInfo?.imageUrl) {
                        e.target.src =
                          toast?.documentInfo?.gender === "M"
                            ? userData?.userInfo?.darkTheme
                              ? "/svgs/Light_Male_Square_PFP.svg"
                              : "/svgs/Dark_Male_Square_PFP.svg"
                            : userData?.userInfo?.darkTheme
                            ? "/svgs/Light_Female_Square_PFP.svg"
                            : "/svgs/Dark_Female_Square_PFP.svg";

                        e.target.className =
                          "critical-toast-card-profile-image";
                      }
                    }}
                  />{" "}
                  <div
                    className={`critical-toast-information-section ${
                      userData?.userInfo?.darkTheme && "toast-border-dark-theme"
                    }`}
                  >
                    <div className="text-truncate mx-3 mt-1">
                      {toast?.documentInfo?.firstName &&
                      toast?.documentInfo?.lastName
                        ? `${toast?.documentInfo?.firstName} ${toast?.documentInfo?.lastName}`
                        : toast?.documentInfo?.firstName
                        ? toast?.documentInfo?.firstName
                        : toast?.documentInfo?.lastName
                        ? toast?.documentInfo?.lastName
                        : "--"}
                    </div>
                    <img
                      src={svgURI}
                      alt="priority description image"
                      style={{ height: 104, width: 90 }}
                    />
                    <div>{priorityDescription}</div>
                    <div className="mt-2" style={{ lineHeight: 1 }}>
                      Location
                    </div>
                    <div style={{ lineHeight: 1 }} className="mt-1">
                      {truncatedLocationType}
                    </div>
                  </div>
                  {/* </div> */}
                </div>
                <div
                  className={`critical-toast-card-bottom-section text-center ${
                    userData?.userInfo?.darkTheme &&
                    "toast-bottom-section-dark-theme-color"
                  }`}
                  style={{ height: 74 }}
                >
                  {" "}
                  {((userData?.entityInformation?.criticalAlertButtons &&
                    userData?.entityInformation?.criticalAlertButtons?.includes(
                      "Occupied"
                    )) ||
                    !userData?.entityInformation?.criticalAlertButtons) && (
                    <Button
                      onClick={() => {
                        toast.setIsCriticalSoundPlaying(false);
                        Howler.stop();
                        busyButton(toast.documentInfo, index);
                      }}
                      className={`critical-toast-card-buttons me-2 mt-2 ${
                        userData?.userInfo?.darkTheme &&
                        "toast-top-section-dark-theme-color toast-border-dark-theme"
                      }`}
                    >
                      Occupied
                    </Button>
                  )}
                  {((userData?.entityInformation?.criticalAlertButtons &&
                    userData?.entityInformation?.criticalAlertButtons?.includes(
                      "Responding"
                    )) ||
                    !userData?.entityInformation?.criticalAlertButtons) && (
                    <Button
                      onClick={() => {
                        toast.setIsCriticalSoundPlaying(false);
                        Howler.stop();
                        respondingButton(toast.documentInfo, index);
                      }}
                      className={`critical-toast-card-buttons mt-2 ${
                        userData?.userInfo?.darkTheme &&
                        "toast-top-section-dark-theme-color toast-border-dark-theme"
                      }`}
                    >
                      Responding
                    </Button>
                  )}
                </div>
                {/* <Toast.Header
                  className={`text-white bg-danger`}
                  closeButton={false}
                >
                  <strong className="me-auto">{toast.title}</strong>
                </Toast.Header> */}
                {/* <Toast.Body className={`text-white bg-danger`}> */}

                {/* </Toast.Body> */}
              </Toast>
            );
          } else {
            // Default rendering for non-critical toasts
            return (
              <Toast
                key={`DelayedToast:${toast?.documentInfo?.id}:${index}`}
                onClose={() => removeToast(index)}
                autohide
                delay={
                  userData?.entityInformation?.alertPreferences?.popupsDelay ||
                  5000
                }
                className={`m-1 status-change-container ${
                  userData?.userInfo?.darkTheme &&
                  "status-change-container-dark"
                }`}
                style={{ zIndex: 9995, cursor: "pointer" }}
                onClick={() => {
                  navigate(
                    `/user/dashboard/subject/${toast?.documentInfo?.id}`
                  );
                  removeToast(index);
                }}
              >
                <div className="d-flex">
                  <img
                    src={
                      toast?.documentInfo?.imageUrl
                        ? `${toast?.documentInfo?.imageUrl}${imageSasToken}`
                        : toast?.documentInfo?.userId
                        ? "/svgs/Light_Checkout_Sensor_PFP.svg"
                        : toast?.documentInfo?.gender === "M"
                        ? userData?.userInfo?.darkTheme
                          ? "/svgs/Light_Male_Square_PFP.svg"
                          : "/svgs/Dark_Male_Square_PFP.svg"
                        : userData?.userInfo?.darkTheme
                        ? "/svgs/Light_Female_Square_PFP.svg"
                        : "/svgs/Dark_Female_Square_PFP.svg"
                    }
                    className={`status-change-toast-image ${
                      userData?.userInfo?.darkTheme && ""
                    }`}
                    onError={(e) => {
                      if (toast?.documentInfo?.imageUrl) {
                        e.target.src =
                          toast?.documentInfo?.gender === "M"
                            ? userData?.userInfo?.darkTheme
                              ? "/svgs/Light_Male_Square_PFP.svg"
                              : "/svgs/Dark_Male_Square_PFP.svg"
                            : userData?.userInfo?.darkTheme
                            ? "/svgs/Light_Female_Square_PFP.svg"
                            : "/svgs/Dark_Female_Square_PFP.svg";

                        e.target.className = "status-change-toast-image";
                      }
                    }}
                  />
                  <div
                    style={{
                      marginTop: 6,
                      marginLeft: 15,
                      color: userData?.userInfo?.darkTheme ? "white" : "black",
                    }}
                  >
                    <div
                      className="text-truncate"
                      style={{
                        width: 200,
                        fontSize: 20,
                        fontWeight: 500,
                      }}
                    >
                      {toast?.documentInfo?.firstName &&
                      toast?.documentInfo?.lastName
                        ? `${toast?.documentInfo?.firstName} ${toast?.documentInfo?.lastName}`
                        : toast?.documentInfo?.firstName
                        ? toast?.documentInfo?.firstName
                        : toast?.documentInfo?.lastName
                        ? toast?.documentInfo?.lastName
                        : "--"}
                    </div>

                    <div
                      style={{
                        width: 210,
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Current State: {priorityDescription}
                    </div>
                    <div
                      style={{
                        width: 200,
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Location: {truncatedLocationType}
                    </div>
                  </div>{" "}
                  <img
                    src={svgURI}
                    alt="priority description image"
                    className="status-change-svg"
                  />
                </div>
              </Toast>
            );
          }
        })}
      </ToastContainer>
    </ToastContext.Provider>
  );
};
