import React, { lazy, Suspense, useContext, useEffect, useState } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";

import Loading from "../Components/Loading";
import NavigationBar from "../Components/NavigationBar";
import ChatBot from "../Components/ChatBot";
import NoWifi from "../Components/NoWifi";
import UserContext from "../Contexts/UserContext/UserContext";
import "./userroutecomponent.css";
import WellnessModal from "../Components/Wellness/WellnessModal";

const Analytics = lazy(() => import("../Components/Analytics/Analytics"));
const MainMonitor = lazy(() => import("../Components/MainMonitor"));
const UserList = lazy(() => import("../Components/UserList"));
const DetailedMonitor = lazy(() => import("../Components/DetailedMonitor"));
const Settings = lazy(() => import("../Components/Settings"));
const ReportsSubjectList = lazy(() =>
  import("../Components/ReportsSubjectList")
);
const ReportsSubjectsData = lazy(() =>
  import("../Components/ReportsSubjectsData")
);
// const Subscriptions = lazy(() => import("../Components/Subscriptions"));

function AdminRouteComponent() {
  const [showWellnessModal, setShowWellnessModal] = useState(true);
  const {
    userPermissions,
    userData,
    wellnessAccepted,
    setWellnessAccepted,
    accessToken,
  } = useContext(UserContext);

  const navigate = useNavigate();

  const [isOnline, setIsOnline] = useState(navigator.onLine); // Track online status
  const closeWellnessModal = () => {
    setShowWellnessModal(false);
  };
  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  useEffect(() => {
    const redirectPath = sessionStorage.getItem("redirectPath");
    if (redirectPath) {
      navigate(redirectPath);
      sessionStorage.removeItem("redirectPath");
    }
  }, []);

  return (
    <React.Fragment>
      <NavigationBar />
      {!isOnline && <NoWifi />}
      <Routes>
        <Route
          path="/user/dashboard"
          element={
            <Suspense fallback={<Loading />}>
              <MainMonitor />
            </Suspense>
          }
        />
        {userPermissions?.includes("viewDetailed") && (
          <Route
            path="/user/dashboard/subject/:subjectId"
            element={
              <Suspense fallback={<Loading />}>
                <DetailedMonitor />
              </Suspense>
            }
          />
        )}
        {userPermissions?.includes("viewEntityUsers") && (
          <Route
            path="/user/users"
            element={
              <Suspense fallback={<Loading />}>
                <UserList />
              </Suspense>
            }
          />
        )}
        {userPermissions?.includes("viewReports") && (
          <Route
            path="/user/reports"
            element={
              <Suspense fallback={<Loading />}>
                <ReportsSubjectList />
              </Suspense>
            }
          />
        )}{" "}
        {userPermissions?.includes("viewReports") && (
          <Route
            path="/user/reports/subject/:subjectId"
            element={
              <Suspense fallback={<Loading />}>
                <ReportsSubjectsData />
              </Suspense>
            }
          />
        )}
        {userPermissions?.includes("viewAnalytics") && (
          <Route
            path="/user/analytics"
            element={
              <Suspense fallback={<Loading />}>
                <Analytics />
              </Suspense>
            }
          />
        )}
        <Route
          path="/user/settings"
          element={
            <Suspense fallback={<Loading />}>
              <Settings />
            </Suspense>
          }
        />
        <Route
          path="/user/settings/*"
          element={
            <Suspense fallback={<Loading />}>
              <Settings />
            </Suspense>
          }
        />
        <Route path="*" element={<Navigate to="/user/dashboard" />} />
      </Routes>
      {userPermissions?.includes("chatBotTest") && <ChatBot />}
      {!wellnessAccepted && (
        <WellnessModal
          userData={userData}
          show={showWellnessModal}
          handleClose={closeWellnessModal}
          title={
            userData?.entityAssets?.wellnessDisclaimerTitle || "Disclaimer"
          }
          children={
            <div>
              {userData?.entityAssets?.wellnessDisclaimer ||
                "By accepting this agreement, the user acknowledges that access to the continuous monitoring features will only be used for a usability evaluation. A physician or specially qualified person using the product outside the intended use does so solely on their order and takes full responsibility."}
            </div>
          }
          disagreeText={
            userData?.entityAssets?.wellnessDisagreeButton || "Disagree"
          }
          agreeText={userData?.entityAssets?.wellnessAgreeButton || "Agree"}
          setWellnessAccepted={setWellnessAccepted}
          accessToken={accessToken}
          // handleConfirm={onSetClicked}
          // started={updatingThreshold}
        />
      )}
    </React.Fragment>
  );
}

export default AdminRouteComponent;
