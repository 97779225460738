import React, { useContext } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import UserContext from "../Contexts/UserContext/UserContext";
function Loading() {
  const { userData } = useContext(UserContext);

  return (
    <Container
      fluid
      className="d-flex justify-content-center align-items-center min-vh-100"
    >
      <Row className="align-items-center">
        <Col className="text-center align-items-center">
          {/* If you want to use a custom image, you can insert it here */}
          {/* <img src="path-to-your-loading-image.gif" alt="Loading..." /> */}

          {/* Alternatively, you can use a spinner from react-bootstrap */}
          <img
            src={
              userData?.userInfo?.darkTheme
                ? "/svgs/4Sight_Labs_Logo_White.svg"
                : "/svgs/4Sight_Labs_Logo_Black.svg"
            }
            alt="Loading..."
            style={{ width: "300px" }}
          />
        </Col>
        <Col className="text-center align-items-center">
          <Spinner
            animation="border"
            style={{ width: "3rem", height: "3rem" }}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default Loading;
