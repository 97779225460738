// UserProvider.js
import React, { useState, useEffect, useContext } from "react";
import userService from "../../../Services/userService";
import * as signalR from "@microsoft/signalr";
import SuperSignalRContext from "./SuperSignalRContext";
import UserContext from "../../UserContext/UserContext";
import SubjectsContext from "../../SubjectsContext/SubjectsContext";
import RoutersContext from "../../RoutersContext/RoutersContext";
// import { useParams } from "react-router-dom";

export const SuperSignalRProvider = ({ children }) => {
  // const { agencyId } = useParams();
  const { userData, setImageSasToken } = useContext(UserContext);
  const [connection, setConnection] = useState(null);
  const [currentSubscriptions, setCurrentSubscriptions] = useState([]);
  const [hasJoinedGroup, setHasJoinedGroup] = useState(false);
  const [chosenAgency, setChosenAgency] = useState(null);
  const { accessToken } = useContext(UserContext);
  const { setSubjects } = useContext(SubjectsContext);
  const { setRouters } = useContext(RoutersContext);
  const [chosenAgencyDetails, setChosenAgencyDetails] = useState(null);

  useEffect(() => {
    if (chosenAgency) {
      userService
        .getEntitysInformation(chosenAgency, accessToken)
        .then((response) => {
          setChosenAgencyDetails(response.data);
        })
        .catch(() => {});
    }
  }, [chosenAgency]);
  // ----- Main SignalR (DO NOT TOUCH) ----- //
  useEffect(() => {
    if (
      userData?.userInfo?.userRole === "4SightAdmin" &&
      chosenAgency &&
      accessToken
    ) {
      userService
        .getImageSasToken(chosenAgency, accessToken)
        .then((response) => {
          setImageSasToken(response.data);
        })
        .catch(() => {});
    }
  }, [userData?.userInfo?.userRole, chosenAgency, accessToken]);

  useEffect(() => {
    // If we already have a connection or no accessToken, there's nothing to do.
    if (connection || !accessToken || !userData) {
      return;
    }

    // No connection exists, let's negotiate a new one.
    userService
      .negotiate(accessToken)
      .then((negotiationResponse) => {
        if (!connection && negotiationResponse && negotiationResponse.data) {
          const newConnection = new signalR.HubConnectionBuilder()
            .withUrl(negotiationResponse.data.url, {
              accessTokenFactory: () => negotiationResponse.data.accessToken,
            })
            .configureLogging(signalR.LogLevel.None)
            .withAutomaticReconnect()
            .build();

          newConnection
            .start()
            .then(() => {
              console.log("SignalR Connection started!");

              // Request the Web Lock to prevent tab sleep when the connection is established
              if (navigator && navigator.locks && navigator.locks.request) {
                navigator.locks.request(
                  "signalr_lock",
                  { mode: "exclusive" },
                  () => {
                    // The lock is kept exclusive to prevent the tab from sleeping
                  }
                );
              }

              setConnection(newConnection);
              setSubjects([]);
            })
            .catch((err) => {
              console.error(
                "Error while establishing SignalR connection:",
                err
              );
            });
        }
      })
      .catch((error) => {
        console.error("Negotiation failed:", error);
      });

    // Cleanup function
    return () => {
      if (connection) {
        connection.stop().then(() => console.log("Disconnected from SignalR."));
      }
    };
  }, [accessToken, connection]);

  // ----- Connection.on for Subjects (DO NOT TOUCH) -----//

  useEffect(() => {
    // Check if the connection is established
    if (connection && typeof connection.on === "function") {
      // Subscribe to the desired event
      connection.on("CPProActiveSubjects", (update) => {
        const newDocument = JSON.parse(update);
        if (newDocument?.entityId === chosenAgency) {
          setSubjects((prevState) => {
            const newArray = [...prevState];

            const updateIndex = newArray.findIndex(
              (subject) => subject.id === newDocument?.id
            );

            if (updateIndex !== -1) {
              if (newDocument?.activeSessionFlag === true) {
                newArray[updateIndex] = {
                  ...newArray[updateIndex],
                  ...newDocument,
                };
              } else {
                newArray.splice(updateIndex, 1); // Removes the item
              }
            } else {
              if (newDocument?.activeSessionFlag === true) {
                newArray.push(newDocument);
              }
            }

            return newArray;
          });
        }
      });

      return () => {
        connection.off("CPProActiveSubjects");
      };
    }
  }, [connection, chosenAgency]);

  // ----- Connection.on for Routers (DO NOT TOUCH) -----//

  useEffect(() => {
    // Check if the connection is established
    if (connection && typeof connection.on === "function") {
      // Subscribe to the desired event
      connection.on("CPProRouters", (update) => {
        const newDocument = JSON.parse(update);

        if (newDocument?.entityId === chosenAgency) {
          setRouters((prevState) => {
            const newArray = [...prevState];

            const updateIndex = newArray.findIndex(
              (subject) => subject.id === newDocument.id
            );

            if (updateIndex !== -1) {
              newArray[updateIndex] = { ...newDocument };
            } else {
              newArray.push(newDocument);
            }

            return newArray;
          });
        }
      });

      return () => {
        connection.off("CPProRouters");
      };
    }
  }, [connection, chosenAgency]);

  // ----- Joining SignalRGroups for Subjects -----//

  useEffect(() => {
    const performLeaveOperation = async () => {
      if (hasJoinedGroup && currentSubscriptions.length > 0) {
        const leavePayload = { groupNames: currentSubscriptions };
        try {
          const leaveResponse = await userService.removeSignalRGroups(
            leavePayload,
            accessToken
          );
          console.log("Left Subscription group successfully", leaveResponse);

          // Now that we've left the old group, perform the join operation
          performJoinOperation();
        } catch (leaveError) {
          console.error("Error leaving group:", leaveError);
        }
      } else {
        // No need to leave, directly perform the join operation
        performJoinOperation();
      }
    };

    const performJoinOperation = async () => {
      // Transform the userLocationSubscription data as previously discussed

      const newSubscriptions = [
        chosenAgency === null ? "--noagencychosen--" : chosenAgency,
      ];

      // Logging

      // Check if subscriptions have changed
      if (
        JSON.stringify(newSubscriptions) !==
        JSON.stringify(currentSubscriptions)
      ) {
        // Logging
        console.log("Subscriptions have changed. Performing join operation...");

        // Perform join operation for the new subscriptions
        const joinPayload = { groupNames: newSubscriptions };
        try {
          await userService.joinSignalRGroups(joinPayload, accessToken);

          setHasJoinedGroup(true);

          // Fetch additional data if needed
          try {
            const additionalDataResponse =
              await userService.getActiveMonitoringSessions(
                chosenAgency,
                accessToken
              );

            setSubjects(additionalDataResponse.data);
          } catch (additionalDataError) {
            if (
              additionalDataError.response.status === 404 &&
              (additionalDataError.response.data ===
                "No active monitoring sessions found." ||
                additionalDataError.response.data === "No entities found")
            ) {
              if (chosenAgency === null) {
                setSubjects(null);
              } else {
                setSubjects([]);
              }
            }
          }
        } catch (joinError) {
          console.error("Error joining group:", joinError);
        }

        // Update the current subscriptions
        setCurrentSubscriptions(newSubscriptions);
      } else {
        // Logging
        console.log("Subscriptions have not changed.");
      }
    };

    if (connection && typeof connection.on === "function" && accessToken) {
      // First, perform the leave operation (if necessary)
      performLeaveOperation();
    }
  }, [connection, accessToken, chosenAgency]);

  // ----- Joining SignalRGroups for Routers (DO NOT TOUCH) -----//

  useEffect(() => {
    let hasJoinedGroup = false;
    const payload = { groupNames: [`Routers-${chosenAgency}`] };

    if (connection && chosenAgency && accessToken) {
      userService
        .joinSignalRGroups(payload, accessToken)
        .then((response) => {
          hasJoinedGroup = true;
          userService
            .getEntityRouters(chosenAgency, accessToken)
            .then((response) => {
              setRouters(response.data);
            })
            .catch(() => {});
        })
        .catch((error) => {
          console.error("Error joining group:", error);
        });
    }

    // Cleanup function
    return () => {
      if (hasJoinedGroup) {
        userService
          .removeSignalRGroups(payload, accessToken)
          .then(() => {})
          .catch(() => {});
      }
    };
  }, [connection, chosenAgency, accessToken]);

  return (
    <SuperSignalRContext.Provider
      value={{
        connection,
        chosenAgency,
        setChosenAgency,
        setCurrentSubscriptions,
        chosenAgencyDetails,
        setChosenAgencyDetails,
      }}
    >
      {children}
    </SuperSignalRContext.Provider>
  );
};
