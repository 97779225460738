import React, { useEffect, useContext } from "react";
import "./App.css";
import { useAuth0 } from "@auth0/auth0-react";
import UserContext from "./Contexts/UserContext/UserContext";
import "bootstrap/dist/css/bootstrap.min.css";
import Loading from "./Components/Loading";

import userService from "./Services/userService";
import { SignalRProvider } from "./Contexts/SignalRContext/SignalRProvider";
import { SuperSignalRProvider } from "./Contexts/SignalRContext/SuperUser/SuperSignalRProvider";
import UserRouteComponent from "./Routes/UserRouteComponent";
import SuperUserRouteComponent from "./Routes/SuperUserRouteComponent";
import { SubjectsProvider } from "./Contexts/SubjectsContext/SubjectsProvider";
import { RoutersProvider } from "./Contexts/RoutersContext/RoutersProvider";
import { ToastProvider } from "./Contexts/ToastContext/ToastProvider";
import ShareRouteComponent from "./Routes/ShareRouteComponent";
import NoAccessRouteComponent from "./Routes/NoAccessRouteComponent";
import PrivacyPolicyRouteComponent from "./Routes/PrivacyPolicyRouteComponent";

function App() {
  const {
    userData,
    setUserData,
    accessToken,
    setAccessToken,
    userPermissions,
  } = useContext(UserContext);

  useEffect(() => {
    if (userData?.userInfo?.darkTheme) {
      document.body.classList.add("dark-theme");
    } else {
      document.body.classList.remove("dark-theme");
    }
  }, [userData?.userInfo?.darkTheme]);

  const {
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    error,
    logout,
    loginWithRedirect,
  } = useAuth0();

  useEffect(() => {
    const currentPath = window.location.pathname;
    const isAccessDenied = currentPath.startsWith("/?error=access_denied");
    if (isAuthenticated && !isAccessDenied) {
      // const options = {
      //   ignoreCache: true,
      //   scope: "read:current_user",
      // };

      getAccessTokenSilently()
        .then((accessToken) => {
          setAccessToken(accessToken);
        })
        .catch((error) => {
          console.error("Error getting the access token", error);
        });
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    const currentPath = window.location.pathname;
    const isDataShareRoute = currentPath.startsWith("/data-share/");
    const isPrivacyPolicyRoute = currentPath.startsWith("/privacypolicy");
    const isAccessDenied = currentPath.startsWith("/?error=access_denied");

    if (
      !isLoading &&
      !isAuthenticated &&
      !isDataShareRoute &&
      !isPrivacyPolicyRoute &&
      !isAccessDenied
    ) {
      const currentPath = window.location.pathname;

      if (currentPath !== "/") {
        sessionStorage.setItem("redirectPath", currentPath);
      }
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  useEffect(() => {
    if (isAuthenticated && accessToken) {
      userService
        .getUserInfo(accessToken)
        .then((response) => {
          setUserData(response.data);
        })
        .catch((error) => {
          if (
            error.response.data === "User's info is not found" &&
            error.response.status === 404
          ) {
            setUserData("User Not Found");
          }
        });
    }
  }, [isAuthenticated, accessToken]);

  // -------------------------------------------------------- RETURN ---------------------------------------------------------- //

  if (error) {
    logout({ returnTo: window.location.origin });
    return <div>Oops... {error.message}</div>;
  }

  if (window.location.pathname.startsWith("/privacypolicy")) {
    return <PrivacyPolicyRouteComponent />;
  }

  if (window.location.pathname.startsWith("/data-share/")) {
    return <ShareRouteComponent />;
  }

  if (isAuthenticated && userData === "User Not Found") {
    return <NoAccessRouteComponent />;
  }

  if (isLoading || !userData || !accessToken || !userData?.userInfo?.userRole) {
    return <Loading />;
  }

  if (isAuthenticated && userData && accessToken && userPermissions) {
    const role = userData?.userInfo?.userRole;

    switch (role) {
      case "4SightAdmin":
        return (
          <RoutersProvider>
            <SubjectsProvider>
              <ToastProvider>
                <SuperSignalRProvider>
                  <SuperUserRouteComponent />
                </SuperSignalRProvider>
              </ToastProvider>
            </SubjectsProvider>
          </RoutersProvider>
        );
      default:
        if (role) {
          return (
            <RoutersProvider>
              <SubjectsProvider>
                <ToastProvider>
                  <SignalRProvider>
                    <UserRouteComponent />
                  </SignalRProvider>{" "}
                </ToastProvider>
              </SubjectsProvider>
            </RoutersProvider>
          );
        } else {
          return <div>Something Went Wrong</div>;
        }
    }
  }
}

export default App;
