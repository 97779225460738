import React, { useState, useEffect, useRef, useContext } from "react";
import { useLocation } from "react-router-dom";
import UserContext from "../Contexts/UserContext/UserContext";

import {
  Button,
  Container,
  Row,
  Col,
  Form,
  FormControl,
} from "react-bootstrap";

import "./chatbot.css";
import userService from "../Services/userService";

function ChatBot() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const [isTyping, setIsTyping] = useState(false);
  const { userData, accessToken } = useContext(UserContext);

  const [messages, setMessages] = useState([
    {
      text: "Hello, Thank you for contacting 4Sight Labs Support! Please type your question and I will do my best to answer it!",
      sender: "bot",
      image: "/svgs/ChatBot/Dark_Chat_Bot.svg", // Path to the bot's image
    },
  ]);
  const [input, setInput] = useState("");

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const bottomSpacing = location.pathname.includes("/user/dashboard/subject/")
    ? "80px"
    : "20px";

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const toggleChat = () => setIsOpen(!isOpen);

  const handleInputChange = (event) => {
    setInput(event.target.value);
  };
  const slackNotification = async (message, response) => {
    const payload = {
      entityName: userData?.userInfo?.entityName,
      entityId: userData?.userInfo?.entityId,
      firstName: userData?.userInfo?.firstName,
      lastName: userData?.userInfo?.lastName,
      email: userData?.userInfo?.email,
      message: message,
      response: response,
    };
    userService
      .chatBotSupportNotification(payload, accessToken)
      .then(() => {})
      .catch(() => {});
  };
  const handleSend = (event) => {
    event.preventDefault();
    if (!input.trim()) return;

    const userMessage = { text: input, sender: "user" };
    setInput(""); // Immediately clear the input field
    setMessages((prevMessages) => [...prevMessages, userMessage]);

    setIsTyping(true); // Simulate bot typing

    const payload = { question: input };
    userService
      .score(payload)
      .then((response) => {
        // setTimeout(() => {

        setIsTyping(false);
        if (
          response.data.output ===
          "Sorry I do not know the answer to your question. Our support team will follow up on your inquiry."
        ) {
          slackNotification(input, response.data.output);
        }
        const botResponse = {
          text: response.data.output,
          sender: "bot",
          image: "/svgs/ChatBot/Dark_Chat_Bot.svg",
        };
        setMessages((prevMessages) => [...prevMessages, botResponse]);
        // }, 1000);
      })
      .catch(() => {
        setIsTyping(false); // Ensure typing is stopped in case of an error
      });
  };

  return (
    <div
      style={{
        position: "fixed",
        bottom: bottomSpacing,
        right: "20px",
        zIndex: "1200",
      }}
    >
      {!isOpen && (
        <Button onClick={toggleChat} className="chat-bot-button">
          <img
            src="/svgs/ChatBot/Dark_Chat_Bot.svg"
            alt="chat-bot_button"
            className="chat-bot-button-image"
          />
        </Button>
      )}
      {isOpen && (
        <Container
          style={{
            backgroundColor: "white",

            overflow: "hidden",
          }}
          className="chat-box-main-container"
        >
          <Row>
            <Col style={{ height: 85.64 }} className="chat-box-support-header">
              <div className="chat-box-top-left-image-container">
                <img
                  src="/svgs/ChatBot/Dark_Chat_Bot.svg"
                  alt="chat-bot_button"
                  className="chat-box-top-left-image"
                />
              </div>
              <div
                style={{
                  fontWeight: 400,
                  fontSize: 31.72,
                  marginTop: 12,
                  color: "black",
                }}
                className="support-header-text"
              >
                Support
              </div>
              {/* <Button variant="secondary" onClick={toggleChat}>
                Close
              </Button> */}
              <Button
                onClick={toggleChat} // Assuming this toggles the visibility of the chat box
                style={{
                  position: "absolute",
                  top: 0, // Adjust as needed
                  right: 0, // Adjust as needed
                  margin: "10px", // Give some space from the corners
                }}
                className="chat-bot-minimize-button"
              >
                <img
                  src="/svgs/ChatBot/Dark_Minimize_Chat_Box.svg"
                  alt="chat-bot-minimize"
                  width={25}
                />
              </Button>
            </Col>
          </Row>
          <Row
            style={{ overflowY: "auto" }}
            className="chat-box-messages-sent-container custom-scrollbar"
          >
            <Col>
              {messages.map((message, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent:
                      message.sender === "bot" ? "flex-start" : "flex-end",
                    marginBottom: "10px",
                  }}
                >
                  {message.sender === "bot" && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start", // Change here to align items at the top
                      }}
                    >
                      <div
                        className="profile-image-chat-profile"
                        style={{
                          borderRadius: "50%",
                          marginRight: "10px",
                          border: "1.59px solid #D4D4D4",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "2px",
                        }}
                      >
                        <img
                          src={message.image}
                          alt="profile"
                          style={{
                            width: "35.38px",
                            height: "31.21px",
                          }}
                        />
                      </div>
                      <p
                        style={{
                          background: "black",
                          color: "white",
                          borderRadius: "15px",
                          padding: "8px",
                          overflowWrap: "break-word",
                          wordBreak: "break-word",
                        }}
                        className="message-sent-container"
                      >
                        {message.text}
                      </p>
                    </div>
                  )}

                  {message.sender === "user" && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start", // Align items at the top
                        justifyContent: "flex-end", // Keep the overall content aligned to the right
                        width: "100%", // Ensure the flex container spans the full width
                      }}
                    >
                      <p
                        style={{
                          background: "white", // Background color for user messages
                          color: "black", // Text color for user messages
                          borderRadius: "15px", // Rounded corners for message bubbles
                          padding: "8px", // Padding inside message bubbles
                          overflowWrap: "break-word", // Ensure long words wrap and do not overflow
                          wordBreak: "break-word",
                          border: "1.59px solid #D4D4D4",
                        }}
                        className="message-sent-container"
                      >
                        {message.text}
                      </p>
                      <div
                        className="profile-image-chat-profile"
                        style={{
                          borderRadius: "50%",
                          marginLeft: "10px",
                          border: "1.59px solid #D4D4D4",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "2px", // Adjust padding as needed
                          backgroundColor: "#f1f1f1", // Optional: background color
                          // Remove align-items and justify-content styles if not adding content inside the div
                        }}
                      >
                        {/* Placeholder or icon can go here if needed */}
                      </div>
                    </div>
                  )}
                </div>
              ))}{" "}
              {isTyping && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginBottom: "10px",
                  }}
                >
                  <div
                    className="profile-image-chat-profile"
                    style={{
                      borderRadius: "50%",
                      marginRight: "10px",
                      border: "1.59px solid #D4D4D4",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "2px",
                    }}
                  >
                    <img
                      src="/svgs/ChatBot/Dark_Chat_Bot.svg"
                      alt="typing-indicator"
                      style={{
                        width: "35.38px",
                        height: "31.21px",
                      }}
                    />
                  </div>
                  <div className="typing-indicator">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              )}
              <div ref={messagesEndRef} />{" "}
              {/* This div will be the scroll target */}
            </Col>
          </Row>
          <Row>
            <Col>
              <Form onSubmit={handleSend} className="d-flex">
                <FormControl
                  type="text"
                  placeholder="Message"
                  value={input}
                  onChange={handleInputChange}
                  style={{ height: 48 }}
                  className="me-2 chat-bot-message-box"
                  disabled={isTyping}
                />
                <Button
                  type="submit"
                  style={{ height: 48 }}
                  className="chat-bot-send-button"
                >
                  <img
                    src="/svgs/ChatBot/Dark_Chat_Send_Button.svg"
                    alt="chat-send"
                  />
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
}

export default ChatBot;
