import React, { useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import userService from "../../Services/userService";

function WellnessModal({
  userData,
  title,
  children,
  show,
  handleClose,
  agreeText = "Agree",
  disagreeText = "Disagree",
  setWellnessAccepted,
  accessToken,
}) {
  const [started, setStarted] = useState(false);

  const wellnessAgreed = () => {
    setStarted(true);
    const payload = {
      entityId: userData?.userInfo?.entityId,
      wellnessAccepted: true,
    };
    userService
      .logWellnessRecord(payload, accessToken)
      .then(() => {
        setWellnessAccepted(true);
        handleClose();
        setStarted(false);
      })
      .catch(() => {
        setStarted(false);
      });
  };
  const wellnessDisagreed = () => {
    setStarted(true);

    const payload = {
      entityId: userData?.userInfo?.entityId,
      wellnessAccepted: false,
    };

    userService
      .logWellnessRecord(payload, accessToken)
      .then(() => {
        setWellnessAccepted(false);
        handleClose();
        setStarted(false);
      })
      .catch(() => {
        setStarted(false);
      });
  };
  return (
    <Modal centered show={show}>
      <Modal.Header
        className={userData?.userInfo?.darkTheme && "dark-card-color"}
        style={{ border: "none" }}
      >
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ fontWeight: 500, border: "none" }}
        className={userData?.userInfo?.darkTheme && "dark-card-color"}
      >
        {children}
      </Modal.Body>
      <Modal.Footer
        style={{ border: "none" }}
        className={userData?.userInfo?.darkTheme && "dark-card-color"}
      >
        {!started ? (
          <React.Fragment>
            <Button variant="danger" onClick={wellnessDisagreed}>
              {disagreeText}
            </Button>

            <Button variant="primary" onClick={wellnessAgreed}>
              {agreeText}
            </Button>
          </React.Fragment>
        ) : (
          <Spinner />
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default WellnessModal;
