import React, { useContext } from "react";
import { Container } from "react-bootstrap";
import UserContext from "../Contexts/UserContext/UserContext";

import "./noaccess.css";
import "./nowifi.css";

function NoWifi() {
  const { userData } = useContext(UserContext);
  return (
    <Container
      fluid
      className={
        userData?.userInfo?.darkTheme
          ? "no-wifi-container-dark"
          : "no-wifi-container"
      }
    >
      <div className="text-center no-access-description">
        Please check your WiFi connection. You currently do not have internet
        access.
      </div>

      <div className="image-container">
        {" "}
        <img
          src="/svgs/NoWifi/No_Wifi.svg"
          alt="no wifi"
          className="no-wifi-image"
        />
      </div>

      <div className="no-access-text">No Wifi</div>
    </Container>
  );
}

export default NoWifi;
