import React, { useContext } from "react";
import "./routerstatus.css";
import { Row, Col, Container, Spinner } from "react-bootstrap";
import RoutersContext from "../Contexts/RoutersContext/RoutersContext";
function RouterStatus({ userData }) {
  const { routers } = useContext(RoutersContext);

  return (
    <Container className="justify-content-center my-2 amount-font">
      <Row
        style={{
          fontSize: 16,
          color: userData?.userInfo?.darkTheme ? "white" : "black",
        }}
        className="text-start"
      >
        Gateway Status:
      </Row>
      <Row style={{ maxWidth: "113px" }} className="justify-content-center ">
        <Col className=" justify-content-end red-router-container text-center me-1 ">
          {" "}
          {routers ? (
            routers?.filter(
              (router) => router.status === "Inactive" || !router.status
            )?.length
          ) : (
            <Spinner size="sm" />
          )}
        </Col>
        <Col className=" justify-content-start green-router-container text-center ">
          {routers ? (
            routers?.filter((router) => router.status === "Active")?.length
          ) : (
            <Spinner size="sm" />
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default RouterStatus;
