import React, { lazy, Suspense, useState, useContext, useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { Row } from "react-bootstrap";
import { Howl } from "howler";
import Loading from "../Components/Loading";
import NavigationBar from "../Components/NavigationBar";
import SubjectsContext from "../Contexts/SubjectsContext/SubjectsContext";
// import ToastContext from "../Contexts/ToastContext/ToastContext";
import UserContext from "../Contexts/UserContext/UserContext";

const AgencyList = lazy(() => import("../Components/SuperUser/AgencyList"));

const MainMonitor = lazy(() => import("../Components/MainMonitor"));
const UserList = lazy(() => import("../Components/UserList"));
const SuperDetailedMonitor = lazy(() =>
  import("../Components/SuperUser/SuperDetailedMonitor")
);
const Settings = lazy(() => import("../Components/Settings"));
const ReportsSubjectList = lazy(() =>
  import("../Components/ReportsSubjectList")
);
const ReportsSubjectsData = lazy(() =>
  import("../Components/ReportsSubjectsData")
);
// const Subscriptions = lazy(() => import("../Components/Subscriptions"));

function SuperUserRouteComponent() {
  const criticalAlertSound = new Howl({
    src: ["/sounds/Critical_Alert.wav"],
  });
  const [notifiedStatuses, setNotifiedStatuses] = useState({});
  const [soundEnabled, setSoundEnabled] = useState(false);
  const { subjects } = useContext(SubjectsContext);
  const { userPermissions } = useContext(UserContext);

  // const { addToast } = useContext(ToastContext);
  const navigate = useNavigate();

  useEffect(() => {
    const checkForCriticalSubjects = () => {
      subjects?.forEach((subject) => {
        const isCritical = subject?.priorityCategory === "Critical";
        const wasCritical = notifiedStatuses[subject.id]?.wasCritical;
        const wasNotified = notifiedStatuses[subject.id]?.wasNotified;

        // Check if the subject is currently critical and either hasn't been notified before,
        // or was not critical last time we checked.
        if (isCritical && (!wasNotified || !wasCritical)) {
          if (
            "Notification" in window &&
            Notification.permission === "granted"
          ) {
            criticalAlertSound.play();
            const notification = new Notification("🚨 CRITICAL ALERT 🚨", {
              body: `${subject?.firstName} ${subject?.lastName} has ${subject?.priorityDescription}`,
              requireInteraction: true,
              data: { url: `/user/dashboard/subject/${subject.id}` }, // Set the URL you want to navigate to
            });

            notification.addEventListener("click", (event) => {
              event.target.close(); // Close the notification when clicked
              const url = event.target.data.url; // Get the URL from the notification data
              if (url) {
                window.focus();
                navigate(url);
              }
            });

            // Update the notified statuses with the new state
            setNotifiedStatuses((prevStatuses) => ({
              ...prevStatuses,
              [subject.id]: { wasNotified: true, wasCritical: true },
            }));
          } else {
            if (soundEnabled) {
              criticalAlertSound.play();
              // addToast(
              //   "🚨 CRITICAL ALERT 🚨",
              //   `${subject?.firstName} ${subject?.lastName} has ${subject?.priorityDescription}`,
              //   "danger"
              // );
              setNotifiedStatuses((prevStatuses) => ({
                ...prevStatuses,
                [subject.id]: { wasNotified: true, wasCritical: true },
              }));
            }
          }
        } else if (wasCritical && !isCritical) {
          // If the subject is no longer critical, update the status to allow future notifications
          setNotifiedStatuses((prevStatuses) => ({
            ...prevStatuses,
            [subject.id]: { ...prevStatuses[subject.id], wasCritical: false },
          }));
        }
      });
    };

    if ("Notification" in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          checkForCriticalSubjects();
        }
      });
    } else {
      checkForCriticalSubjects();
    }
  }, [subjects, notifiedStatuses, soundEnabled]);

  const toggleSoundPreference = () => {
    const newSoundEnabled = !soundEnabled;
    setSoundEnabled(newSoundEnabled);

    // localStorage.setItem("soundEnabled", newSoundEnabled.toString());
  };

  // useEffect(() => {
  //   if (!("Notification" in window)) {
  //     const userSoundPreference = localStorage.getItem("soundEnabled");
  //     if (userSoundPreference === "true") {
  //       setSoundEnabled(true);
  //     }
  //   }
  // }, []);
  useEffect(() => {
    const redirectPath = sessionStorage.getItem("redirectPath");
    if (redirectPath) {
      navigate(redirectPath);
      sessionStorage.removeItem("redirectPath");
    }
  }, []);
  return (
    <React.Fragment>
      <NavigationBar />

      {!("Notification" in window) && !soundEnabled && (
        <Row className="justify-content-center">
          <div className="text-center">
            <button onClick={toggleSoundPreference}>
              {soundEnabled ? "Disable Notifications" : "Enable Notifications"}
            </button>
          </div>
        </Row>
      )}
      <Routes>
        <Route
          path="/4SightAdmin/agencies"
          element={
            <Suspense fallback={<Loading />}>
              <AgencyList />
            </Suspense>
          }
        />
        <Route
          path="/4SightAdmin/agency/:agencyId"
          element={
            <Suspense fallback={<Loading />}>
              <MainMonitor />
            </Suspense>
          }
        />
        <Route
          path="/user/dashboard/agency/:agencyId/subject/:subjectId"
          element={
            <Suspense fallback={<Loading />}>
              <SuperDetailedMonitor />
            </Suspense>
          }
        />

        <Route
          path="/4SightAdmin/agency/:agencyId/users"
          element={
            <Suspense fallback={<Loading />}>
              <UserList />
            </Suspense>
          }
        />

        <Route
          path="/4SightAdmin/agency/:agencyId/reports"
          element={
            <Suspense fallback={<Loading />}>
              <ReportsSubjectList />
            </Suspense>
          }
        />

        <Route
          path="/4SightAdmin/agency/:agencyId/reports/subject/:subjectId"
          element={
            <Suspense fallback={<Loading />}>
              <ReportsSubjectsData />
            </Suspense>
          }
        />

        <Route
          path="/user/settings"
          element={
            <Suspense fallback={<Loading />}>
              <Settings />
            </Suspense>
          }
        />
        <Route
          path="/user/settings/*"
          element={
            <Suspense fallback={<Loading />}>
              <Settings />
            </Suspense>
          }
        />

        <Route
          path="/user/settings/agency/:agencyId"
          element={
            <Suspense fallback={<Loading />}>
              <Settings />
            </Suspense>
          }
        />
        <Route
          path="/user/settings/agency/:agencyId/*"
          element={
            <Suspense fallback={<Loading />}>
              <Settings />
            </Suspense>
          }
        />

        {/* <Route
          path="/user/settings/subscriptions"
          element={
            <Suspense fallback={<Loading />}>
              <Subscriptions />
            </Suspense>
          }
        /> */}
        <Route path="*" element={<Navigate to="/4SightAdmin/agencies" />} />
      </Routes>
    </React.Fragment>
  );
}

export default SuperUserRouteComponent;
