import React, { useEffect, useState, useContext } from "react";
import { Navbar, Nav, Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import RouterStatus from "./RouterStatus";
import "./navigationbar.css";
import { useAuth0 } from "@auth0/auth0-react";
import UserContext from "../Contexts/UserContext/UserContext";
import SuperSignalRContext from "../Contexts/SignalRContext/SuperUser/SuperSignalRContext";
import ConfirmingModal from "./ConfirmingModal";

function NavigationBar() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { logout } = useAuth0();
  const { userData, userPermissions, imageSasToken } = useContext(UserContext);
  const { chosenAgency, chosenAgencyDetails } = useContext(SuperSignalRContext);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [startedLogout, setStartedLogout] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const handleLogout = () => {
  //   logout({ logoutParams: { returnTo: window.location.origin } });
  //   // The `returnTo` parameter should be a URL to which Auth0 will redirect users after they log out. window.location.origin
  // };
  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const handleNavLinkClick = () => {
    setIsMenuOpen(false);
  };
  const openLogoutModal = () => {
    setShowLogoutModal(true);
  };
  const closeLogoutModal = () => {
    setShowLogoutModal(false);
  };
  const handleLogout = () => {
    setStartedLogout(true);
    logout({
      logoutParams: {
        returnTo: window.location.origin,
        federated: "https://login.4sightlabs.com/v2/logout?federated",
      },
    });
    // The `returnTo` parameter should be a URL to which Auth0 will redirect users after they log out.
  };
  return (
    <React.Fragment>
      <Navbar
        // fixed="top"
        expanded={isMenuOpen}
        expand={
          (userPermissions?.includes("viewEntityUsers") &&
            userPermissions?.includes("viewReports")) ||
          (userData?.userInfo?.userRole === "4SightAdmin" && chosenAgency)
            ? "lg"
            : "md"
        }
        sticky="top"
        className={`${
          userData?.userInfo?.darkTheme
            ? "navbar-background-color-dark"
            : "navbar-background-color"
        } shadow`}
      >
        <Container fluid>
          {" "}
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={handleMenuToggle}
            className={userData?.userInfo?.darkTheme && "custom-toggle"}
          ></Navbar.Toggle>
          <Navbar.Brand onClick={handleNavLinkClick} as={Link} to="/">
            <img
              src={
                userData?.userInfo?.darkTheme
                  ? "/svgs/4Sight_Labs_Logo_White.svg"
                  : "/svgs/4Sight_Labs_Logo_Black.svg"
              }
              width="150"
              className="align-top mx-3 expanded-logo-display"
              alt="4Sight Labs Logo"
            />
            <img
              src={
                userData?.userInfo?.darkTheme
                  ? "/svgs/4SightLabs_Mini_Logo.svg"
                  : "/svgs/4SightLabs_Mini_Logo_Black.svg"
              }
              width="37"
              className="align-top mx-3 mini-logo-display"
              alt="4Sight Labs Logo"
            />
          </Navbar.Brand>
          {isMobile &&
          ((userData?.userInfo?.userRole === "4SightAdmin" && chosenAgency) ||
            userData?.userInfo?.userRole !== "4SightAdmin") ? (
            <div>
              <Row className="justify-content-center">
                <Col style={{ width: "150px" }} className="text-center">
                  {" "}
                  <RouterStatus userData={userData} />
                </Col>
              </Row>
            </div>
          ) : (
            <div></div>
          )}
          <Navbar.Collapse id="basic-navbar-nav">
            <Row className="w-100 justify-content-center">
              <Col xs={12}>
                <Nav className="me-auto justify-content-center">
                  <Nav.Link
                    onClick={handleNavLinkClick}
                    as={Link}
                    to={
                      userData?.userInfo?.userRole === "4SightAdmin"
                        ? "/4SightAdmin/agencies"
                        : "/user/dashboard"
                    }
                    className="navigation-link-spacing navigation-link"
                    style={{
                      color: userData?.userInfo?.darkTheme ? "white" : "black",
                    }}
                  >
                    <img
                      src={
                        userData?.userInfo?.darkTheme
                          ? "/svgs/Light_Home.svg"
                          : "/svgs/Dark_Home.svg"
                      } // Replace with the path to your SVG
                      width="25" // Adjust the size as needed
                      className="mb-1 me-1"
                      alt="Home"
                    />{" "}
                    Home
                  </Nav.Link>
                  {(userPermissions?.includes("viewEntityUsers") ||
                    (userData?.userInfo?.userRole === "4SightAdmin" &&
                      chosenAgency)) && (
                    <Nav.Link
                      onClick={handleNavLinkClick}
                      as={Link}
                      to={
                        userData?.userInfo?.userRole === "4SightAdmin" &&
                        chosenAgency
                          ? `/4SightAdmin/agency/${chosenAgency}/users`
                          : "/user/users"
                      }
                      className="navigation-link-spacing navigation-link"
                      style={{
                        color: userData?.userInfo?.darkTheme
                          ? "white"
                          : "black",
                      }}
                    >
                      <img
                        src={
                          userData?.userInfo?.darkTheme
                            ? "/svgs/Light_Users.svg"
                            : "/svgs/Dark_Users.svg"
                        } // Replace with the path to your SVG
                        width="30" // Adjust the size as needed
                        className="me-1 mb-1"
                        alt="Home"
                      />{" "}
                      Users
                    </Nav.Link>
                  )}
                  {(userPermissions?.includes("viewReports") ||
                    (userData?.userInfo?.userRole === "4SightAdmin" &&
                      chosenAgency)) && (
                    <Nav.Link
                      onClick={handleNavLinkClick}
                      as={Link}
                      to={
                        userData?.userInfo?.userRole === "4SightAdmin" &&
                        chosenAgency
                          ? `/4SightAdmin/agency/${chosenAgency}/reports`
                          : "/user/reports"
                      }
                      className="navigation-link-spacing navigation-link"
                      style={{
                        color: userData?.userInfo?.darkTheme
                          ? "white"
                          : "black",
                      }}
                    >
                      <img
                        src={
                          userData?.userInfo?.darkTheme
                            ? "/svgs/Navbar/Light_Reports.svg"
                            : "/svgs/Navbar/Dark_Reports.svg"
                        } // Replace with the path to your SVG
                        width="19" // Adjust the size as needed
                        className="me-1 mb-1"
                        alt="Home"
                      />{" "}
                      Reports
                    </Nav.Link>
                  )}
                  <Nav.Link
                    onClick={handleNavLinkClick}
                    as={Link}
                    style={{
                      color: userData?.userInfo?.darkTheme ? "white" : "black",
                    }}
                    to={
                      userData?.userInfo?.userRole === "4SightAdmin" &&
                      chosenAgency
                        ? `/user/settings/agency/${chosenAgency}`
                        : "/user/settings"
                    }
                    className="navigation-link-spacing navigation-link align-items-center"
                  >
                    <img
                      src={
                        userData?.userInfo?.darkTheme
                          ? "/svgs/Light_Settings.svg"
                          : "/svgs/Dark_Settings.svg"
                      } // Replace with the path to your SVG
                      width="23" // Adjust the size as needed
                      className="me-1 mb-1"
                      alt="Home"
                    />{" "}
                    Settings
                  </Nav.Link>
                </Nav>
              </Col>
            </Row>
            {isMobile !== true &&
            ((userData?.userInfo?.userRole === "4SightAdmin" && chosenAgency) ||
              userData?.userInfo?.userRole !== "4SightAdmin") ? (
              <div>
                <Row className="justify-content-center">
                  <Col style={{ width: "150px" }} className="text-center">
                    {" "}
                    <RouterStatus userData={userData} />
                  </Col>
                </Row>
              </div>
            ) : (
              <div style={{ height: 70 }}></div>
            )}
            {/* <Dropdown align="end" className="ms-4 me-1 text-center">
            <Dropdown.Toggle as="a" className="nav-link"> */}
            <div className="text-center">
              {((userData?.userInfo?.userRole === "4SightAdmin" &&
                chosenAgency) ||
                userData?.userInfo?.userRole !== "4SightAdmin") && (
                <img
                  src={
                    userData?.userInfo?.userRole === "4SightAdmin" &&
                    chosenAgency
                      ? chosenAgencyDetails?.entityLogoUrl
                        ? `${chosenAgencyDetails?.entityLogoUrl}${imageSasToken}`
                        : userData?.userInfo?.darkTheme
                        ? "/svgs/Navbar/Light_Entity_Logo_Placeholder.svg"
                        : "/svgs/Navbar/Dark_Entity_Logo_Placeholder.svg"
                      : userData?.entityInformation?.entityLogoUrl
                      ? `${userData?.entityInformation?.entityLogoUrl}${imageSasToken}`
                      : userData?.userInfo?.darkTheme
                      ? "/svgs/Navbar/Light_Entity_Logo_Placeholder.svg"
                      : "/svgs/Navbar/Dark_Entity_Logo_Placeholder.svg"
                  }
                  alt="users_placeholder_profile"
                  className="ms-4 me-1 text-center entity-logo"
                  // width={45}
                />
              )}
            </div>{" "}
            {isMobile !== true && (
              <Button
                className="px-4"
                style={{
                  color: userData?.userInfo?.darkTheme ? "white" : "black",
                  backgroundColor: "transparent",
                  border: "none",
                  fontSize: 18,
                  fontWeight: 500,
                }}
                onClick={openLogoutModal}
              >
                <div className="d-flex">
                  <img
                    src={
                      userData?.userInfo?.darkTheme
                        ? "/svgs/Settings/Light_Logout.svg"
                        : "/svgs/Settings/Dark_Logout.svg"
                    }
                    alt="Theme"
                    className="mb-1 me-2"
                    width={20}
                  />
                  Logout
                </div>
              </Button>
            )}
            {/* </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item as={Link} to="/profile">
                Profile
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/settings">
                Settings
              </Dropdown.Item>
              Add more dropdown items here
              <Dropdown.Divider />
              <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <ConfirmingModal
        userData={userData}
        show={showLogoutModal}
        handleClose={closeLogoutModal}
        title={`Confirm Logout`}
        children={<div>Are you sure you want to logout?</div>}
        handleConfirm={handleLogout}
        started={startedLogout}
      />
    </React.Fragment>
  );
}

export default NavigationBar;
