// UserProvider.js
import React, { useEffect, useState } from "react";
import UserContext from "./UserContext";
import userService from "../../Services/userService";

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [imageSasToken, setImageSasToken] = useState(null);
  const [userPermissions, setUserPermissions] = useState(null);
  const [wellnessAccepted, setWellnessAccepted] = useState(false);

  // Context value now includes the function to fetch user data
  const contextValue = {
    userData,
    setUserData,
    accessToken,
    setAccessToken,
    imageSasToken,
    setImageSasToken,
    userPermissions,
    wellnessAccepted,
    setWellnessAccepted,
  };

  useEffect(() => {
    if (userData?.entityInformation?.entityPreference?.wellnessEnabled) {
      setWellnessAccepted(false);
    } else {
      setWellnessAccepted(true);
    }
  }, [userData?.entityInformation]);

  useEffect(() => {
    if (
      !accessToken ||
      !userData?.userInfo?.entityId ||
      userData?.userInfo?.userRole === "4SightAdmin"
    ) {
      return;
    }
    userService
      .getImageSasToken(userData?.userInfo?.entityId, accessToken)
      .then((response) => {
        setImageSasToken(response.data);
      })
      .catch(() => {});
  }, [accessToken, userData?.userInfo?.entityId, userData?.userInfo?.userRole]);

  useEffect(() => {
    if (
      userData?.userInfo?.userRole &&
      userData?.entityInformation?.entityRoles
    ) {
      let userPermissions = [];
      const matchingRole = userData?.entityInformation?.entityRoles.find(
        (entityRole) => entityRole.role === userData?.userInfo?.userRole
      );

      if (matchingRole) {
        userPermissions = matchingRole.permissions;
      }
      setUserPermissions(userPermissions);
    }
  }, [userData?.userInfo?.userRole, userData?.entityInformation?.entityRoles]);

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};
