import React from "react";
import { Modal, Button, Spinner } from "react-bootstrap";

function ConfirmingModal({
  userData,
  title,
  children,
  show,
  handleClose,
  handleConfirm,
  confirmText = "Confirm",
  started,
}) {
  return (
    <Modal centered show={show}>
      <Modal.Header
        className={userData?.userInfo?.darkTheme && "dark-card-color"}
        style={{ border: "none" }}
      >
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ fontWeight: 500, border: "none" }}
        className={userData?.userInfo?.darkTheme && "dark-card-color"}
      >
        {children}
      </Modal.Body>
      <Modal.Footer
        style={{ border: "none" }}
        className={userData?.userInfo?.darkTheme && "dark-card-color"}
      >
        {!started ? (
          <React.Fragment>
            <Button variant="danger" onClick={handleClose}>
              Close
            </Button>

            <Button variant="primary" onClick={handleConfirm}>
              {confirmText}
            </Button>
          </React.Fragment>
        ) : (
          <Spinner />
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmingModal;
